<div class="section" *ngIf="modalData.acknowledgement; else downloadDialog">
  <section *ngIf="startup_popup_1; else startup_popup_2">
    <div style="display: flex; align-items: center; flex-grow: 1; height: 100%">
      <p class="text-info">
        <strong>Discover Safely</strong>: FInD empowers explorations with sample
        data, max 300 rows.
        <br />
        <strong>Restricted, sensitive and PII data are excluded.</strong>
      </p>
    </div>
    <button class="action-btn" (click)="startup_popup_1 = false">
      I Agree. Proceed
    </button>
  </section>
  <ng-template #startup_popup_2>
    <section>
      <div
        style="display: flex; align-items: center; flex-grow: 1; height: 100%"
      >
        <p class="text-info">
          <strong>Discover the Power of FInD:</strong> Personalized for you,
          Enhanced by your usage. <br /><br />
          Queries? Write to
          <a
            target="_blank"
            style="font-weight: 600"
            href="https://forms.office.com/pages/responsepage.aspx?id=Aq5v9jZdW0m_4Him_5-Obqvi98FBjsZNpLf28XfFaPFUNEVZRTgyTFlDUU9ZQzNIR1daWkNaSkpHWi4u"
          >
            us</a
          >!
        </p>
      </div>
      <button class="action-btn" (click)="closeDialog()">
        I Agree. Proceed
      </button>
    </section>
  </ng-template>
</div>
<ng-template #downloadDialog>
  <section
    style="background-color: #e9ecef"
    *ngIf="!modalData.isDownloaded; else downloadeddialog"
  >
    <label class="file-spec-bar" for="filename"
      ><p>File Name :</p>
      <input
        type="text"
        name="text"
        id="filename"
        class="input"
        [(ngModel)]="modalData.filename"
      />
    </label>
    <label class="file-spec-bar" style="margin-bottom: 0px" for="format"
      >File Format :
      <select id="format" style="margin-left: 5px">
        <option>
          {{ modalData.file_format }}
        </option>
      </select>
    </label>
    <button
      class="action-btn"
      style="margin-top: 2.5rem; width: 151px; font-size: large"
      (click)="closeDialog()"
    >
      <p>Download</p>
    </button>
  </section>
</ng-template>
<ng-template #downloadeddialog>
  <section>
    <img
      style="margin-bottom: 1.25rem"
      src="assets/icon/Submit-success-icon.svg"
    />
    <h3>Downloaded Successfully!</h3>
    <button
      class="action-btn"
      style="margin-top: 2.5rem"
      (click)="closeDialog()"
    >
      OK
    </button>
  </section>
</ng-template>
