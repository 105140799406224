import { Component, Inject, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-modal",
  standalone: true,
  imports: [CommonModule, MatDialogModule,FormsModule],
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.css"],
})
export class ModalComponent implements OnInit {
  modalData: any;
  startup_popup_1:boolean = true;
  
  constructor(
    private dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.modalData = this.data;
  }

  closeDialog() {
    localStorage.setItem('acknowledgement','closed')
    if(this.modalData.isDownloaded) this.dialogRef.close();
    else this.dialogRef.close(this.modalData.filename)
  }
}
