import { Inject, Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { DOCUMENT } from "@angular/common";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(@Inject(DOCUMENT) private document: any) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        next: (event) => {
          return event;
        },
        error: (error) => {
          if(!error.ok && error.statusText.toLowerCase() === 'unknown error') {
            const domain = this.document.location;
            // window.location.href = domain.origin;
          }
        },
      })
    );
  }
}
